/* Add these styles to your nav.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1efe9 !important; 
    padding: 1rem; 
    height: 90px;
  }
ul.navbar-nav > li > a {
    line-height: 100px;
  }
  
  .logo-container {
    flex: 1;
    display: flex;
    align-items: center;
    position: absolute;
  }
  
  .logo {
    height: 200px;
  }
  
  .menuItems {
    flex: 2;
    display: flex;
    justify-content: center;
    color: black;
    padding-left: 400px;
  }

  

  .menu-item{
    padding: 0 1rem;
    font-size: 1.2rem !important;
    font-weight: 500;
    color: black;
    text-decoration: none;
    transition: all 0.2s ease-in;
  }
 
  
  .menu-item:hover {
    transform: scale(1.2);
    color: navy;
  }

  .accountIcon {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  
  }

  
  

  .menu-item,
  .tooltip-button {
    &:hover {
      background-color: transparent !important;
    }
    &:hover .MuiTouchRipple-root {
      display: none !important;
    }
  }
  