.questions {
  display: flex;
  justify-content: space-between;
}

.item-card {
  flex: 1;
}

.search-container {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.card {
  flex: 1;
  max-width: 400;
}

.question {
  cursor: pointer;
}

.answerForm {
  margin-top: 15px;
}

.answerInput {
  width: 100%;
  height: 100px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.submitButton {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
