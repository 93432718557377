@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated-section {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .fade-in {
    animation: fade-in 1s ease forwards;
  }

  @keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide {
  animation: slide-in 1s ease forwards;
}

@keyframes example {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(20px);
    }
  }
  