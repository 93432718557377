@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.questionsPage {
    display: grid;
    grid-template-rows: auto 1fr; 
    gap: 20px; 
    min-height: 100vh; 
    background-color: #f1efe9;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 128);
  }
  
  .page {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr; 
  }


  @media screen and (max-width: 768px) {
    .questionsPage {
        display: grid;
        grid-template-rows: auto 1fr; 
        gap: 20px; 
        min-height: 100vh; 
        background-color: #f1efe9;
      }
      
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
        background-color: rgba(0, 0, 128);
      }
      
      .page {
        display: grid;
        grid-template-columns: auto; 
      }
  }