.spinning-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 300px;
    height: 300px;
    animation: spin 10s linear infinite;
    margin-top: 10rem;
    margin-left: 28rem;
    margin-bottom: 10rem;
  }
  
  .spinning-circle {
    width: 100%;
    background-color: #f1efe9;
    height: 100%;
    border-radius: 50%;
    border: 8px solid navy;
    position: relative;
   
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
  